<template>
  <div>
    <TopMenuSubheadline :title="title"/>
    <div class="app-content-box bg-gray">
      <div class="wrapper container">
        <div class="row mt-4">
          <div class="book-cover col-6">
            <img
              :src="book.image ? (coverUrl + book.image) : (coverUrl + 'no_cover.png')"
            />
          </div>
          <div class="col-6 pl-0">
          <router-link v-if="book.author_id" class="text-dark mt-3" :to="{name: 'Authors', params: {id: book.author_id}}">
            <p class="book-side-author-alt mb-4">{{book.autor}}</p>
          </router-link>
          <router-link v-if="book.id" class="text-dark mt-3" :to="{name: 'BooksDetails', params: {id: book.id}}">
            <h3 class="book-side-title-big">{{book.tytul}}</h3>
          </router-link>
          </div>
        </div>
        <div v-if="friendsList.length">
          <div class="filter-wrapper mt-3 mb-3">
            <input type="text" name="filterFriends" class="input-filter-friends" placeholder="Wyszukaj znajomych" v-model="search"/>
            <div class="ml-3"><i class="fas fa-search"></i></div>
          </div>
        <div class="people-wrapper mt-2 mb-5">
          <div v-for="(person, key) in filtered" :key="key">
            <div  class="row  mb-3 d-flex align-items-center" @click="selectPerson(key, person.id)">
              <div class="col-4">
                <div :class="{'cover checked-cover': selected.filter(function(item) { return item == person.id }).length > 0 }">
                    <img :src="person.thumb_url ? plainUrl + person.thumb_url : require('../assets/avatar-placeholder.jpg')" class="notification-avatar"/>
                </div>
              </div>
              <div class="col-8"><div class="people-wrapper-title">{{person.username}}</div></div>
          </div>
            </div>
        </div>
        </div>
        <div v-else>
          <div class="text-center mt-5"><strong>Nie masz jeszcze znajomych, którym możesz polecić książkę</strong></div>
        </div>
      </div>
    </div>
    <div class="button-item-container">
      <div class="container">
          <button type="button" class="btn-black-full w-100" @click="sendData" v-if="friendsList.length">Poleć książkę</button>
          <button type="button" class="btn-black-full w-100" @click="$router.go(-1)" v-else>Anuluj</button>
      </div>
    </div>
  </div>
</template>
<script>
import TopMenuSubheadline from '../components/TopMenuSubheadline'
export default {
  name: 'RecommendBook',
  components: {
    TopMenuSubheadline
  },
  props: {
    id: [String, Number]
  },
  async created () {
    await this.$https('/book/' + this.id, {
      method: 'GET',
      mode: 'no-cors',
      crossDomain: true,
      headers: {
      }
    })
      .then(({ data }) => {
        this.book = data.response.book
      })
      .catch(error => {
        console.log('', error)
      })

    this.$https('/friends', {
      method: 'GET',
      mode: 'no-cors',
      crossDomain: true,
      headers: {
      }
    })
      .then(res => {
        this.friendsList = res.data.response
      })
      .catch(error => {
        console.log('', error)
      })
  },
  data () {
    return {
      title: 'Poleć książkę znajomym',
      avatar: 'avatar-image-big.jpg',
      selected: [],
      search: '',
      book: [],
      friendsList: []
    }
  },
  computed: {
    filtered () {
      return this.friendsList.filter(x => {
        return x.username.toLowerCase().includes(this.search.toLowerCase())
      })
    }
  },
  methods: {
    sendData () {
      this.selected.forEach(e => {
        this.$https('/share/book/' + this.id + '/' + e, {
          method: 'POST',
          mode: 'no-cors',
          crossDomain: true,
          headers: {
          // 'Content-Type': 'application/x-www-form-urlencoded'
          }
        }).then(response => {
        })
          .catch(error => {
            console.log('', error)
          })
      })
      this.$router.push('/ksiazka/' + this.id)
    },
    selectPerson (key, personId) {
      let isSelected = false
      let toDelete = 0
      this.selected.forEach(e => {
        if (e === parseInt(personId)) {
          isSelected = true
          toDelete = this.selected.indexOf(e)
        }
      })

      if (isSelected === false) {
        this.selected.push(parseInt(personId))
      } else {
        this.selected.splice(toDelete, 1)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .app-content-box {
    min-height: 100vh;
  }
  .wrapper {
    margin-top: 50px;
  }
  .book-side-author-alt {
    font-size: 18px;
    line-height: 20px;
  }
  .book-side-title-big {
    word-break: break-word;
  }
  .book-cover img {
    width: 100%;
  }
  .button-item-container {
  width: 100%;
  color: #000;
  background-color: #f1f1f1;
  z-index: 100;
  position: absolute;
  bottom: 65px;
  z-index: 0;
  box-shadow: 0 0 50px 40px #f1f1f1de;
  }

  .people-wrapper {
    height: 40vh;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    &-title {
      font-size: 20px;
    }
  }

  .notification-avatar {
    width: 85px;
    height: 85px;
    border-radius: 50%;
  }
  .cover {
    max-height: 100%;
    width: 100%;
    display: block;

    &::before {
      display: block;
      width: 85px;
      height: 85px;
      margin-left: 15px;
      border-radius: 50%;
      content: "";
      background-color: #000;
      opacity: 0;
      transition: opacity 0.5s ease;
      z-index: 50;
    }
    &::after {
      content: 'aaa';
      display: block;
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      color: #fff;
      z-index: 100;
      content: '\f00c';
      position: absolute;
      top: 45%;
      left: 45%;
    }
    &.checked {
      &-cover {
        &::before {
          display: block;
          content: "";
          opacity: 0.5;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
  .filter-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .input-filter-friends {
    width: 100%;
    border: none;
    border-bottom: 1px solid black;
    background-color: transparent;
    font-size: 14px;

    &:active, &:focus {
      font-size: 14px;
    }
  }
</style>
